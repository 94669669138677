/**
 * @author José Rafael Gutierrez
 * @param {Object} subcategory
 * @param {Boolean} accomplished
 * @returns {number}
 */
export function avg(subcategory, accomplished) {
  return accomplished ? subcategory.leagueAccomplishedAvg : subcategory.leagueReceivedAvg;
}

/**
 * @author José Rafael Gutierrez
 * @param {Object} subcategory
 * @param {Boolean} accomplished
 * @returns {number}
 */
export function max(subcategory, accomplished) {
  return accomplished ? subcategory.leagueAccomplishedMax : subcategory.leagueReceivedMax;
}
