<template>
  <div class="column section-height">
    <div v-for="(subcategory, name, index) in filteredSubcategories" :key="index">
      <!-- Fila para la categoria -->
      <div
        class="columns is-mobile has-horizontal-divider is-marginless has-horizontal-divider"
        :style="{ 'background-color': subcategory.isBetMainSubcategory ? '#FDFDE2' : '#ffffff' }"
      >
        <!-- Local -->
        <div class="column has-text-left team-data" :class="homeTeamDataClasses">
          <div class="is-flex" style="justify-content: space-between;">
            <div
              v-if="!showPostMatchData"
              class="is-marginless category-postmatch-value-left is-clickable"
              @click="
                showModal(
                  $event,
                  subcategory.homeTeam[homeTeamStatisticsType],
                  leagueAvg(subcategory),
                  subcategory.names,
                  true,
                )
              "
            >
              {{ subcategory.homeTeam[homeTeamStatisticsType].avgPostGame | toFixed2 }}
            </div>
            <div
              v-else
              class="is-marginless category-postmatch-value-left is-clickable"
              @click="
                showModal(
                  $event,
                  subcategory.homeTeam[homeTeamStatisticsType],
                  leagueAvg(subcategory),
                  subcategory.names,
                  true,
                )
              "
            >
              {{ subcategory.homeTeam[homeTeamStatisticsType].totalPostGame }}
            </div>
            <div
              v-if="showLiveData"
              class="category-live-value is-clickable"
              @click="
                showModal(
                  $event,
                  subcategory.homeTeam[homeTeamStatisticsType],
                  leagueAvg(subcategory),
                  subcategory.names,
                  true,
                )
              "
            >
              {{ subcategory.homeTeam[homeTeamStatisticsType].totalLive }}
            </div>
          </div>
          <span class="is-size-7 category-ranking" v-if="!showPostMatchData">
            (Ranking: #{{ subcategory.homeTeam[homeTeamStatisticsType].ranking }})
          </span>
        </div>
        <!-- Separador -->
        <div class="has-divider-vertical"></div>
        <!-- Categoria -->
        <div class="column has-text-centered" :class="isMobile ? 'mobile-column-center' : ''">
          <div class="is-size-5 is-marginless category-name">
            <!-- '0-0' contains default name, subcategories from possession only has one possible name -->
            <strong>{{ subcategory.names[subcategoryName] || subcategory.names['0-0'] }}</strong>
          </div>
          <div class="is-size-7 category-league-average">
            <span v-if="showAvg">
              Promedio {{ isFromBet ? 'Liga MX' : currentTournamentName }}: {{ leagueAvg(subcategory) }}
            </span>
          </div>
        </div>
        <!-- Separador -->
        <div class="has-divider-vertical"></div>
        <!-- Visitante -->
        <div class="column has-text-right team-data" :class="awayTeamDataClasses">
          <div class="is-flex" style="justify-content: space-between;">
            <div
              v-if="showLiveData"
              class="is-size-3 is-inline-block category-live-value is-clickable"
              @click="
                showModal(
                  $event,
                  subcategory.visitingTeam[visitingTeamStatisticsType],
                  leagueAvg(subcategory),
                  subcategory.names,
                  false,
                )
              "
            >
              {{ subcategory.visitingTeam[visitingTeamStatisticsType].totalLive }}
            </div>
            <div
              v-if="!showPostMatchData"
              class="is-size-3 is-inline-block category-postmatch-value-right is-clickable"
              style="margin-left: auto;"
              @click="
                showModal(
                  $event,
                  subcategory.visitingTeam[visitingTeamStatisticsType],
                  leagueAvg(subcategory),
                  subcategory.names,
                  false,
                )
              "
            >
              <div class="category-postmatch-value-righ-indiv">
                {{ subcategory.visitingTeam[visitingTeamStatisticsType].avgPostGame | toFixed2 }}
              </div>
            </div>
            <div
              v-else
              class="is-size-3 is-inline-block category-postmatch-value-right is-clickable"
              style="margin-left: auto;"
              @click="
                showModal(
                  $event,
                  subcategory.visitingTeam[visitingTeamStatisticsType],
                  leagueAvg(subcategory),
                  subcategory.names,
                  false,
                )
              "
            >
              {{ subcategory.visitingTeam[visitingTeamStatisticsType].totalPostGame }}
            </div>
          </div>
          <span class="is-size-7 category-ranking" v-if="!showPostMatchData">
            (Ranking: #{{ subcategory.visitingTeam[visitingTeamStatisticsType].ranking }})
          </span>
        </div>
      </div>
      <!-- Fila para la anotacion especial -->
      <div
        v-if="subcategory.mentions[subcategoryName].length > 0"
        class="columns"
        style="margin-top: 0; margin-bottom:0;"
      >
        <div class="column category-annotation">
          <p class="is-size-7">
            {{ subcategory.mentions[subcategoryName] }}
          </p>
        </div>
      </div>
    </div>
    <ResumeTooltip
      :data="tooltip.data"
      :local="tooltip.local"
      :names="tooltip.names"
      :keyName="tooltip.keyName"
      :leagueAvg="tooltip.leagueAvg"
      :left="tooltip.local"
      :show.sync="tooltip.show"
      :x="tooltip.x"
      :y="tooltip.y"
    />
  </div>
</template>

<script>
import ResumeTooltip from '@/components/Elements/NextMatches/ResumeTooltip';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { avg } from '@/utils/league';

export default {
  name: 'Resumen',
  components: {
    ResumeTooltip,
  },
  props: {
    isFromBet: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltip: {
        show: false,
        data: {},
        names: {},
        keyName: '',
        leagueAvg: '',
        local: false,
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    ...mapState('general', ['tournamentSelected']),
    ...mapState('nextGeneral', ['selectedAnalysis', 'currentTournamentName']),
    ...mapState('loginGeneral', ['user']),
    ...mapGetters('nextGeneral', [
      'isLive',
      'showLiveData',
      'showPostMatchData',
      'statisticData',
      'homeTeamStatisticsType',
      'visitingTeamStatisticsType',
      'subcategoryName',
      'accomplished',
      'filledDataStructure',
      'betSubcategoriesIds',
    ]),
    filteredSubcategories() {
      if (this.isFromBet) {
        const betSubcategories = Object.values(this.filledDataStructure).reduce((accumulator, category) => {
          Object.values(category.subcategories).forEach(subcategory => {
            if (this.betSubcategoriesIds.includes(subcategory.generalId)) {
              accumulator.push(subcategory);
            }
          });
          return accumulator;
        }, []);
        this.setBetSubcategoriesObjects(betSubcategories);
        return betSubcategories;
      }
      return this.statisticData;
    },
    awayTeamDataClasses() {
      return [
        this.isMobile ? 'mobile-column-right' : 'margin-right-12',
        !this.user.premiumAccount && this.isFromBet ? 'blurred' : '',
      ];
    },
    homeTeamDataClasses() {
      return [
        this.isMobile ? 'mobile-column-left' : 'margin-left-12',
        !this.user.premiumAccount && this.isFromBet ? 'blurred' : '',
      ];
    },
    showAvg() {
      if (!this.showPostMatchData) {
        return this.subcategoryName === '1-1' || this.subcategoryName === '0-0';
      } else {
        return false;
      }
    },
    isMobile() {
      return this.$mq === 'mobile';
    },
  },
  methods: {
    ...mapMutations('nextGeneral', ['setBetSubcategoriesObjects']),
    showModal(event, data, leagueAvg, names, local) {
      this.tooltip.data = data;
      this.tooltip.local = local;
      this.tooltip.names = names;
      this.tooltip.keyName = this.subcategoryName;
      this.tooltip.leagueAvg = leagueAvg;
      this.tooltip.x = event.pageX;
      if (!this.showPostMatchData) {
        this.tooltip.y = event.pageY > 700 ? event.pageY - 250 : event.pageY;
      } else {
        this.tooltip.y = event.pageY > 700 ? event.pageY - 70 : event.pageY;
      }
      this.tooltip.show = true;
    },
    leagueAvg(subcategory) {
      return avg(subcategory, this.accomplished);
    },
  },
};
</script>

<style scoped lang="scss">
$blur: 10px;
.has-divider-vertical {
  height: auto;
  border-left: 1px dotted #bebcbc;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
}

.has-horizontal-divider {
  border-bottom: 1px solid #d7d7d7;
}

.category-name {
  font-family: 'Circular-Std';
  font-size: 1.063rem;
}

.team-data {
  position: relative;

  &.blurred {
    filter: blur($blur);
    -webkit-filter: blur($blur);
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 1;
  }
}

@media (max-width: 400px) {
  .category-name {
    font-size: 1rem !important;
  }
  .category-postmatch-value-left {
    font-size: 24px !important;
    width: 32px;
  }
  .category-postmatch-value-right {
    font-size: 24px !important;
    width: 32px;
  }
  .category-postmatch-value-righ-indiv {
    text-align: right;
    width: 52px;
    float: right;
  }
  .category-live-value {
    font-size: 24px !important;
    width: 34px;
  }
}

@media (max-width: 350px) {
  .category-name {
    font-size: 0.8rem !important;
  }
  .category-postmatch-value-left {
    font-size: 24px !important;
    width: 30px;
  }
  .category-postmatch-value-right {
    font-size: 24px !important;
    width: 30px;
  }
  .category-live-value {
    font-size: 24px !important;
    width: 30px;
  }
}

.category-league-average {
  font-family: 'Avenir-Demi';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.002rem;
}

.category-postmatch-value-left {
  text-align: left;
  font-family: 'Circular-Std';
  font-size: 29px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: black;
}

.category-postmatch-value-right {
  text-align: right;
  font-family: 'Circular-Std';
  font-size: 29px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: black;
}

.category-live-value {
  margin: 0 8px;
  font-family: 'Circular-Std';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.005rem;
  text-align: center;
  color: #ffffff;
  background-color: #77a405;
  border-radius: 8px;
  min-width: 46px;
}

.category-ranking {
  font-family: 'Avenir-Demi';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #5e5e5e;
}

.category-annotation {
  font-family: 'Avenir-Demi';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  color: #ffffff;
  background-color: #77ba3f;
  text-align: center;
}

.margin-bottom-12 {
  margin-bottom: calc(1.5rem - 0.75rem) !important;
}

.margin-left-12 {
  margin-left: 1rem !important;
}

.margin-right-12 {
  margin-right: 1rem !important;
}

.data-container {
  display: flex;
}

.mobile-column-center {
  padding: 12px 4px 12px 4px;
}

.mobile-column-left {
  padding: 12px 0 12px 0;
}

.mobile-column-right {
  padding: 12px 0 12px 0;
}

.category-postmatch-value-left:hover,
.category-postmatch-value-right:hover {
  color: #77a405 !important;
}
.category-live-value:hover {
  background-color: #658909;
}
</style>
