<template>
  <div v-if="show" class="tooltip" @click="onClose">
    <div
      :key="tooltipkey"
      class="tooltip-content"
      :class="classDefine"
      :style="isMobile ? '' : [positionTooltipDesktop, sizeTooltipDesktop]"
    >
      <span v-show="showClose" class="close" @click="onClose">&times;</span>
      <div class="header">
        <slot name="header"></slot>
      </div>
      <div class="body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Tooltip',
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    left: {
      type: Boolean,
      required: false,
      default: true,
    },
    width: {
      type: Number,
      required: false,
      default: 240,
    },
    height: {
      type: Number,
      required: false,
      default: 235,
    },
  },
  data() {
    return {
      close: false,
      tooltipkey: 0,
    };
  },
  computed: {
    ...mapGetters('nextGeneral', ['showPostMatchData']),
    isMobile() {
      return this.$mq === 'mobile';
    },
    isFromForce() {
      return this.width > 300;
    },
    sizeTooltipDesktop() {
      var height = this.height;
      if (this.showPostMatchData) {
        height = 130;
      }
      return {
        width: this.width + 'px',
        minHeight: height + 'px',
      };
    },
    positionTooltipDesktop() {
      return this.left
        ? {
            top: this.y + 'px',
            left: this.x + 20 + 'px',
          }
        : {
            top: this.y + 'px',
            left: this.x - this.width - 20 + 'px',
          };
    },

    classDefine() {
      // eslint-disable-next-line no-unused-vars
      if (!this.showPostMatchData) {
        return (this.isMobile ? 'mobile' : 'desktop') + (this.isMobile && this.isFromForce ? ' is-from-force' : '');
      } else {
        var value =
          (this.isMobile ? 'mobile-postmatch' : 'desktop') +
          (this.isMobile && this.isFromForce ? ' is-from-force' : '');

        return value;
      }
    },
  },
  methods: {
    onClose() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.tooltip {
  color: white;
  position: fixed; /* Stay in place */
  z-index: 5; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background-color: rgb(0, 0, 0); /* Fallback color */
  /* background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.tooltip-content {
  background-color: #272e3e;
  border: 1px solid #888;
}

.tooltip-content.desktop {
  position: fixed;
}

.tooltip-content.mobile {
  width: 240px;
  min-height: 240px;
  margin: 50% auto;
}

.tooltip-content.mobile-postmatch {
  width: 240px;
  min-height: 140px;
  margin: 50% auto;
}

@media screen and (max-width: 768px) {
  .is-from-force {
    width: 450px !important;
  }
}

@media screen and (max-width: 562px) {
  .is-from-force {
    width: 80% !important;
  }
}

.tooltip .header {
  font-family: 'Circular-Std';
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 9px 0;
  background-color: #1a2130;
}

.tooltip .header h1 {
  color: white;
}

.tooltip .close {
  color: #aaa;
  float: right;
  font-size: 20px;
  padding-right: 2px;
  font-weight: bold;
}

.tooltip .close:hover,
.tooltip .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
