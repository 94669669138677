var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column section-height"},[_vm._l((_vm.filteredSubcategories),function(subcategory,name,index){return _c('div',{key:index},[_c('div',{staticClass:"columns is-mobile has-horizontal-divider is-marginless has-horizontal-divider",style:({ 'background-color': subcategory.isBetMainSubcategory ? '#FDFDE2' : '#ffffff' })},[_c('div',{staticClass:"column has-text-left team-data",class:_vm.homeTeamDataClasses},[_c('div',{staticClass:"is-flex",staticStyle:{"justify-content":"space-between"}},[(!_vm.showPostMatchData)?_c('div',{staticClass:"is-marginless category-postmatch-value-left is-clickable",on:{"click":function($event){_vm.showModal(
                  $event,
                  subcategory.homeTeam[_vm.homeTeamStatisticsType],
                  _vm.leagueAvg(subcategory),
                  subcategory.names,
                  true,
                )}}},[_vm._v(" "+_vm._s(_vm._f("toFixed2")(subcategory.homeTeam[_vm.homeTeamStatisticsType].avgPostGame))+" ")]):_c('div',{staticClass:"is-marginless category-postmatch-value-left is-clickable",on:{"click":function($event){_vm.showModal(
                  $event,
                  subcategory.homeTeam[_vm.homeTeamStatisticsType],
                  _vm.leagueAvg(subcategory),
                  subcategory.names,
                  true,
                )}}},[_vm._v(" "+_vm._s(subcategory.homeTeam[_vm.homeTeamStatisticsType].totalPostGame)+" ")]),(_vm.showLiveData)?_c('div',{staticClass:"category-live-value is-clickable",on:{"click":function($event){_vm.showModal(
                  $event,
                  subcategory.homeTeam[_vm.homeTeamStatisticsType],
                  _vm.leagueAvg(subcategory),
                  subcategory.names,
                  true,
                )}}},[_vm._v(" "+_vm._s(subcategory.homeTeam[_vm.homeTeamStatisticsType].totalLive)+" ")]):_vm._e()]),(!_vm.showPostMatchData)?_c('span',{staticClass:"is-size-7 category-ranking"},[_vm._v(" (Ranking: #"+_vm._s(subcategory.homeTeam[_vm.homeTeamStatisticsType].ranking)+") ")]):_vm._e()]),_c('div',{staticClass:"has-divider-vertical"}),_c('div',{staticClass:"column has-text-centered",class:_vm.isMobile ? 'mobile-column-center' : ''},[_c('div',{staticClass:"is-size-5 is-marginless category-name"},[_c('strong',[_vm._v(_vm._s(subcategory.names[_vm.subcategoryName] || subcategory.names['0-0']))])]),_c('div',{staticClass:"is-size-7 category-league-average"},[(_vm.showAvg)?_c('span',[_vm._v(" Promedio "+_vm._s(_vm.isFromBet ? 'Liga MX' : _vm.currentTournamentName)+": "+_vm._s(_vm.leagueAvg(subcategory))+" ")]):_vm._e()])]),_c('div',{staticClass:"has-divider-vertical"}),_c('div',{staticClass:"column has-text-right team-data",class:_vm.awayTeamDataClasses},[_c('div',{staticClass:"is-flex",staticStyle:{"justify-content":"space-between"}},[(_vm.showLiveData)?_c('div',{staticClass:"is-size-3 is-inline-block category-live-value is-clickable",on:{"click":function($event){_vm.showModal(
                  $event,
                  subcategory.visitingTeam[_vm.visitingTeamStatisticsType],
                  _vm.leagueAvg(subcategory),
                  subcategory.names,
                  false,
                )}}},[_vm._v(" "+_vm._s(subcategory.visitingTeam[_vm.visitingTeamStatisticsType].totalLive)+" ")]):_vm._e(),(!_vm.showPostMatchData)?_c('div',{staticClass:"is-size-3 is-inline-block category-postmatch-value-right is-clickable",staticStyle:{"margin-left":"auto"},on:{"click":function($event){_vm.showModal(
                  $event,
                  subcategory.visitingTeam[_vm.visitingTeamStatisticsType],
                  _vm.leagueAvg(subcategory),
                  subcategory.names,
                  false,
                )}}},[_c('div',{staticClass:"category-postmatch-value-righ-indiv"},[_vm._v(" "+_vm._s(_vm._f("toFixed2")(subcategory.visitingTeam[_vm.visitingTeamStatisticsType].avgPostGame))+" ")])]):_c('div',{staticClass:"is-size-3 is-inline-block category-postmatch-value-right is-clickable",staticStyle:{"margin-left":"auto"},on:{"click":function($event){_vm.showModal(
                  $event,
                  subcategory.visitingTeam[_vm.visitingTeamStatisticsType],
                  _vm.leagueAvg(subcategory),
                  subcategory.names,
                  false,
                )}}},[_vm._v(" "+_vm._s(subcategory.visitingTeam[_vm.visitingTeamStatisticsType].totalPostGame)+" ")])]),(!_vm.showPostMatchData)?_c('span',{staticClass:"is-size-7 category-ranking"},[_vm._v(" (Ranking: #"+_vm._s(subcategory.visitingTeam[_vm.visitingTeamStatisticsType].ranking)+") ")]):_vm._e()])]),(subcategory.mentions[_vm.subcategoryName].length > 0)?_c('div',{staticClass:"columns",staticStyle:{"margin-top":"0","margin-bottom":"0"}},[_c('div',{staticClass:"column category-annotation"},[_c('p',{staticClass:"is-size-7"},[_vm._v(" "+_vm._s(subcategory.mentions[_vm.subcategoryName])+" ")])])]):_vm._e()])}),_c('ResumeTooltip',{attrs:{"data":_vm.tooltip.data,"local":_vm.tooltip.local,"names":_vm.tooltip.names,"keyName":_vm.tooltip.keyName,"leagueAvg":_vm.tooltip.leagueAvg,"left":_vm.tooltip.local,"show":_vm.tooltip.show,"x":_vm.tooltip.x,"y":_vm.tooltip.y},on:{"update:show":function($event){return _vm.$set(_vm.tooltip, "show", $event)}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }