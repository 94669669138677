<template>
  <Tooltip :show="show" :show-close="isMobile" :x="x" :y="y" :left="left" @close="onClose">
    <template v-slot:header>
      {{ name }}
    </template>
    <template v-slot:body>
      <div class="statsFiltered-container">
        <div class="is-flex">
          <figure class="image is-24x24 is-inline-flex is-marginless">
            <img :src="team.logo" alt="team logo" />
          </figure>
          <span class="team-name"> {{ team.team_name }}</span>
        </div>
        <template v-if="!showPostMatchData">
          <div>
            Promedio {{ name }}:
            <span>{{ data.avgPostGame | toFixed2 }}</span>
          </div>
          <div v-if="showLiveData">
            LIVE:
            <span>{{ data.totalLive | toFixed2 }}</span>
          </div>
          <div>
            Promedio {{ currentTournamentName }}: <span>{{ leagueAvg }}</span>
          </div>
          <div>
            Ranking: <span>{{ data.ranking }}</span>
          </div>
          <div class="is-flex">
            Líder en esta categoría:
            <span>
              <figure class="image is-24x24 is-inline-flex is-marginless leader-logo" :title="teamLeader.team_name">
                <img
                  :src="'https://az755631.vo.msecnd.net/teams-80/' + teamLeader.team_id + '.png'"
                  alt="leader logo"
                />
              </figure>
            </span>
          </div>
        </template>
        <template v-else>
          <div>
            {{ name }}:
            <span>{{ data.totalPostGame }}</span>
          </div>
        </template>
      </div>
    </template>
  </Tooltip>
</template>

<script>
import Tooltip from '@/components/Elements/NextMatches/Tooltip';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ResumeTooltipModal',
  components: {
    Tooltip,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    data: {
      type: Object,
      required: true,
    },
    names: {
      type: Object,
      required: true,
    },
    keyName: {
      type: String,
      required: true,
    },
    leagueAvg: {
      type: [Number, String],
      required: true,
    },
    local: {
      type: Boolean,
      required: false,
    },
    left: {
      type: Boolean,
      required: false,
      default: true,
    },
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('general', ['tournamentSelected']),
    ...mapState('nextGeneral', [
      'gameForAnalysis',
      'currentSeasonTeams',
      'showLiveData',
      'homeTeam',
      'visitingTeam',
      'currentTournamentName',
    ]),
    ...mapGetters('nextGeneral', ['showPostMatchData']),
    isMobile() {
      return this.$mq === 'mobile';
    },
    name() {
      var KEY = this.local ? `${this.keyName[0]}-1` : `${this.keyName[2]}-1`;
      if (this.names[KEY] != '') {
        return Object.keys(this.names).length ? this.names[KEY].split(' / ')[0] : '';
      } else {
        KEY = '0-0';
        return Object.keys(this.names).length ? this.names[KEY].split(' / ')[0] : '';
      }
    },
    teamId() {
      return this.local ? this.gameForAnalysis.home_team : this.gameForAnalysis.visiting_team;
    },
    team() {
      return this.currentSeasonTeams.find(team => team.team_id === this.teamId) || { team_name: '', logo: '' };
    },
    teamLeader() {
      return (
        this.currentSeasonTeams.find(team => this.data.leader && team.team_id === this.data.leader.teamId) || {
          team_name: '',
          logo: '',
        }
      );
    },
  },
  methods: {
    onClose() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped>
.statsFiltered-container {
  margin-top: 12px;
  margin-left: 20px;
  font-family: 'Avenir-Demi';
  font-size: 12px;
  line-height: 2.17;
  text-align: left;
}
.statsFiltered-container div > span {
  font-family: 'Avenir-Regular';
}
.team-name {
  margin-left: 8px;
  font-family: 'Avenir-Demi' !important;
  font-size: 13px;
  font-weight: bold;
}
.leader-logo {
  margin-left: 8px !important;
}
</style>
